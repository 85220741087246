import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { animated } from 'react-spring';
import { useFormTransition } from '../../../utils/hooks/useFormTransition';

import { Section } from '../../layout/Section';
import { Container } from '../../layout/Container';
import { BackgroundLoad } from '../../layout/BackgroundLoad';
import { Thanks } from '../../layout/Thanks';

import { Heading } from '../../ui/text/Heading';
import { Paragraph } from '../../ui/text/Paragraph';

import { AccordionGeneral } from '../../partials/AccordionGeneral';
import { AccordionBenefits } from '../../partials/AccordionBenefits';
import { useFadeTransition } from '../../../utils/hooks/useFadeTransition';
import { Seo } from '../../../utils/seo/Index';

import { AboutValuationText } from '../../partials/AboutValuationText';
import { PageType } from '../../../__types__/generated/globalTypes';
import { useValuationContext } from '../../../utils/hooks/useValuationContext';
import { ValuationForm } from '../../partials/forms/Valuation';
import { Form, FormRow } from '../../ui/form/Form';
import { FormError } from '../../ui/form/FormError';
import { Input } from '../../ui/form/Input';
import { ButtonGroup } from '../../ui/button/ButtonGroup';
import { Button } from '../../ui/button/Button';
import { ButtonLink } from '../../ui/link/ButtonLink';
import { useMutation } from '@apollo/client';
import { ILeadInput, ILeadResponse } from '../../../__types__/global';
import { MAIL_MUTATION } from '../../../../client/__graphql__/mutations/mail';
import { SiteContext } from '../../../utils/context/SiteContext';
import { useForm } from '../../../utils/hooks/useForm';

const Sandviken: React.FC = () => {
  const [posted, setPosted] = React.useState(false);
  const { routeConfig }: any = React.useContext(SiteContext);

  const { fadeIn: pageFadeIn } = useFadeTransition({
    immidiate: true
  });

  const { fadeIn, fadeOut } = useFormTransition({
    posted
  });

  const [mail, { loading, error }] = useMutation<ILeadResponse, ILeadInput>(
    MAIL_MUTATION,
    {
      onCompleted: ({ lead }) => {
        if (lead.mail?.success) {
          setPosted(true);
        }
      }
    }
  );
  const { fields, getFields, handleSubmit, handleChange } = useForm({
    fields: [
      {
        type: 'text',
        name: 'name',
        placeholder: 'Eks. Ola Nordmann',
        value: '',
        label: 'Navn *',
        required: true
      },
      {
        type: 'text',
        name: 'zip',
        placeholder: 'Eks. 0000',
        value: '',
        label: 'Postnummer *',
        required: true
      },
      /*       {
        type: 'text',
        name: 'address',
        placeholder: 'Eks. Adresseveien 23',
        value: '',
        label: 'Adresse *',
        required: true
      }, */
      {
        type: 'tel',
        name: 'phone',
        placeholder: 'Eks. 00000000',
        value: '',
        label: 'Telefon *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      },
      {
        type: 'email',
        name: 'email',
        placeholder: 'Eks. ole@mail.no',
        value: '',
        label: 'E-post *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      },
      {
        type: 'textarea',
        name: 'comment',
        placeholder: 'Skriv kommentar',
        value: '',
        label: 'Kommentar'
      }
    ],
    submit: () => {
      const preparedFields: any = getFields(fields);
      mail({
        variables: {
          input: {
            ...preparedFields,
            referer: window.location.href,
            leadType: 'EPOST',
            mailType: 86
          }
        }
      });
    }
  });

  return (
    <>
      <Seo
        title="PrivatMegleren - Skal du selge eiendom i Sandviken?"
        description="Vi vet at beliggenhet er langt mer enn utsikt og gode solforhold."
        image="https://cdn.reeltime.no/pm_assets/sandviken/stemmemyren.jpg"
      />
      <Container className="padding-top" style={pageFadeIn}>
        <Section className="form-section" style={{ paddingBottom: '10px' }}>
          <Heading tag="h1" center={true}>
            Skal du selge eiendom i Sandviken?
          </Heading>
          <Paragraph center={true} className="form-text">
            Vi vet at beliggenhet er langt mer enn utsikt og gode solforhold.
            Derfor har vi i en årrekke vært hovedsponsor for breddeidretten i
            Sandviken IL.
          </Paragraph>
          <Paragraph center={true} className="form-text">
            Vi både bor og jobber i nabolaget. Når du skal selge bolig kan vi
            derfor tilby deg et ekstra godt tilbud, samt meglere som virkelig
            kjenner nabolaget. Lokalkunnskap er viktig, både for forståelse av
            marked og prisnivå. God lokalkunnskap gjør oss også i stand til å
            markedsføre eiendommer her på en effektiv måte, samt svare på
            spørsmål fra kunder.
          </Paragraph>
          <Paragraph center={true} className="form-text">
            Når du velger en megler fra PrivatMegleren spanderer vi visningene
            (ord. kr 3.490), i tillegg oppgraderer vi vår digitale annonsering
            fra liten til medium pakke uten å øke prisen (verdi kr 2.000). Skal
            du kjøpe bolig før du selger, vil vi også tilby deg{' '}
            <a href="https://privatmegleren.no/kjope-eiendom">
              basis kjøpsmegling
            </a>{' '}
            uten ekstra kostand (verdi kr 24.950).
          </Paragraph>

          <Paragraph center={true} className="form-text">
            Du kan bestille et uforpliktende tilbud ved å sende inn skjema.
          </Paragraph>
        </Section>
        <FormWrapper style={fadeOut}>
          <Form
            onSubmit={handleSubmit}
            noValidate
            style={{ paddingBottom: '50px' }}
          >
            {error &&
              error.graphQLErrors.map((error, index) => (
                <FormError key={`error_${index}`} message={error.message} />
              ))}
            {fields
              .filter((item) => item?.type !== 'textarea')
              .map((item, index) => {
                return (
                  <FormRow key={`field_${item?.name}`}>
                    <Input
                      type={item?.type}
                      name={item?.name}
                      label={item?.label}
                      labelAfter={item?.labelAfter}
                      placeholder={item?.placeholder}
                      value={item?.value}
                      onChange={handleChange}
                      error={item?.error}
                    />
                  </FormRow>
                );
              })}
            {/*             {fields
              .filter((item) => item?.type === 'textarea')
              .map((item) => (
                <FormRow key={`field_${item?.name}`}>
                  <Textarea
                    name={item?.name}
                    label={item?.label}
                    labelAfter={item?.labelAfter}
                    placeholder={item?.placeholder}
                    value={item?.value}
                    onChange={handleChange}
                    error={item?.error}
                  />
                </FormRow>
              ))} */}
            <ButtonGroup>
              <Button
                type="primary"
                colorTheme="gold"
                disabled={loading}
                loading={loading}
              >
                Kontakt meg
              </Button>
              <ButtonLink
                as="a"
                href={routeConfig.personvern.url}
                className="privacy-link-mobile"
              >
                Personvernpolicy
              </ButtonLink>
            </ButtonGroup>
          </Form>
        </FormWrapper>
        <Thanks style={fadeIn}>
          <Section className="form-section">
            <Heading tag="h2" center={true}>
              Tusen takk
            </Heading>
            <Paragraph center={true}>
              En eiendomsmegler vil ta kontakt med deg så snart som mulig.
            </Paragraph>
          </Section>
        </Thanks>
      </Container>
      <BackgroundLoad
        opacity={0.3}
        url="https://cdn.reeltime.no/pm_assets/sandviken/stemmemyren.jpg"
      />
    </>
  );
};

export default Sandviken;

const FormWrapper = styled(animated.div)``;
